// Dependency
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"
import DynamicComponent from "../helpers/storyblok/dynamicComponent"
import GA4 from "../services/tracking"
import { checkBackgroundColor } from "../helpers/checkBackgroundColor"
import { storyblokEditable } from "gatsby-source-storyblok"

// Components
import ProductDescription from "../components/product/product-description/product-description"
import ProductCarouselInfo from "../components/product/product-carousel-info/product-carousel-info"
import ProductReviewBlock from "../components/product/product-review-block/product-review-block"

const Product = ({ data, pageContext }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)

  const components = story.content.section?.map(section => {
    return section.content?.widget?.map(blok => (
      <DynamicComponent blok={blok} key={blok._uid} />
    ))
  })
  const bgColor = checkBackgroundColor(
    pageContext?.variables?.variable?.product?.backgroundColor
  )
  useEffect(() => {
    const product = data.brink.getProduct
    if (product.price)
      GA4.viewItem(data.brink.getProduct, pageContext.store.currencyUnit)
  }, [data.brink.getProduct])

  const gallery = story.content.gallery.find(i => i.component === "carousel")
  const infoCard = story.content.productInfo.find(
    i => i.component === "info-card"
  )
  const productDescription = story.content.productDescription.find(
    i => i.component === "product-description"
  )
  const reviewBlok = story.content.section.find(i => i.slug === "pdp-review")
  const title = story.name

  return (
    <div className="product-page">
      <div {...storyblokEditable(story.content)}>
        <ProductCarouselInfo
          infoCard={infoCard}
          brink={data.brink}
          gallery={gallery}
          bgColor={bgColor}
          productdescription={productDescription}
        />
      </div>
      {/* <div {...storyblokEditable(story.content)}>
        <ProductDescription
          showWarranty={story.content.showWarranty}
          perks={infoCard.bulletPoints}
          title={title}
          props={productDescription}
        />
      </div> */}

      {components}
      {reviewBlok ? (
        <ProductReviewBlock
          productInternalId={data.brink.getProduct.id}
          title={reviewBlok.content.title}
          reviewCountText={reviewBlok.content.reviewCountText}
          loadMoreText={reviewBlok.content.loadMoreText}
        />
      ) : undefined}
    </div>
  )
}

export default Product

export { Head } from "./page"

export const query = graphql`
  query ProductQuery($full_slug: String!, $sku: ID!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      ...StoryEntry
    }
    brink {
      ...BrinkVariant
    }
  }
`
